import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axios';

export const getStudents = createAsyncThunk("instituteStudent", async (instituteId, { rejectWithValue }) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    let reqOptions = {
        url: `/students/${instituteId}`,
        method: "GET",
        headers: headersList,
    };
    
    try {
        const response = await axios.request(reqOptions)
        return response
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

export const deleteStudents = createAsyncThunk("deleteStudents", async (ids) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({"id": ids.studentId})

    let reqOptions = {
        url: `/students/deleteStudent`,
        method: "DELETE",
        headers: headersList,
        data: bodyContent
    };
    
    let reqOptionsUpdate = {
        url: `/students/${ids.instituteId}`,
        method: "GET",
        headers: headersList,
    };

    try {
        const response = await axios.request(reqOptions)
        if(response.status === 200)
        {
            const newStudents = await axios.request(reqOptionsUpdate)
            return newStudents
        }
    } catch (error) {
        return {message: error.message};
    }
})

export const updateStudents = createAsyncThunk("updateStudents", async (data) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({"id": data.studentId, "updatedData": data.newData})

    let reqOptions = {
        url: `/students/updateStudent`,
        method: "PUT",
        headers: headersList,
        data: bodyContent
    };
    
    let reqOptionsUpdate = {
        url: `/students/${data.instituteId}`,
        method: "GET",
        headers: headersList,
    };

    try {
        const response = await axios.request(reqOptions)
        if(response.status === 200)
        {
            const newStudents = await axios.request(reqOptionsUpdate)
            return newStudents
        }
    } catch (error) {
        return {message: error.message};
    }
})

const studentSlice = createSlice({
    name: "student",
    initialState: {
        data: [],
        loading: false,
        error: ''
    },
    reducers: {
        resetStudent: (state) => {
            state.data = []
            state.loading = false
            state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getStudents.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getStudents.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            state.error = ''
        })
        builder.addCase(getStudents.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
        builder.addCase(deleteStudents.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(updateStudents.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export const { resetStudent } = studentSlice.actions

export default studentSlice.reducer 