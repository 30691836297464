import React, { useState, useEffect } from 'react'
import '../styles/CreateInstituteForm.css'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { useNavigate } from 'react-router-dom'
import { updateInstitutes } from '../store/slices/InstituteSlice'
import resetInstitute from '../store/slices/UserSlice'

const UpdateInstituteForm = () => {

    const [data, setData] = useState({name: "", place: "", pincode: "", userId: ""})
    const [formErr, setFormErr] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => {
        return state.user.id
    })

    const instituteId = useSelector((state) => {
        return state.user.currentInstitute
    })

    const handleChange = ({currentTarget: input}) => {
        setData({...data, "userId": userId, [input.name]: input.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(data.name.length === 0 || data.place.length === 0 || data.pincode.length === 0){
            setFormErr(true)
        }else{
            setFormErr(false)
            let customObj = {
                userId: userId,
                instituteId: instituteId,
                newData: data
            }
            dispatch(updateInstitutes(customObj))
            navigate(-1, {replace: true})
        }
    }

    const fetchInstituteDetails = async (instituteId) => {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let bodyContent = JSON.stringify(data);

        let reqOptions = {
            url: `/institutes/getInstitute/${instituteId}`,
            method: "GET",
            headers: headersList,
            data: bodyContent,
        };

        try {
            let response = await axios.request(reqOptions);
            if(response.status === 200){
                console.log(response.data);
                setData(response.data)
            } else if (response.status === 401) {
                    window.alert("Invalid Password");
            }
        } catch (err) {
            window.alert(`${err.response.data.message}!`)
        }
    }

    useEffect(() => {
        fetchInstituteDetails(instituteId)
    }, [])

    const handleResetInstitute = () => {
        dispatch(resetInstitute())
    }

  return (
    <div className='instiFormMainContainer'>
        <h1 className="instiFormTitle">Update Institute</h1>
        <div className="instiFormInnerContainer">
            <div className="instiInputContainer">
                <label htmlFor="instiName">Name of the Institute</label>
                <input 
                        id="instiName"
                        className="instiInputs" 
                        type="text" 
                        placeholder="Name"
                        name="name"
                        onChange={handleChange}
                        value={data.name}
                        />
                        {formErr&&data.name.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="instiInputContainer">
                <label htmlFor="instiPlace">Place of the Institute</label>
                <input 
                        id="instiPlace"
                        className="instiInputs" 
                        type="text" 
                        placeholder="Place"
                        name="place"
                        onChange={handleChange}
                        value={data.place}
                        />
                        {formErr&&data.place.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="instiInputContainer">
            <label htmlFor="instiPincode">Pincode of Location</label>
            <input 
                    id="instiPincode"
                    className="instiInputs" 
                    type="text" 
                    placeholder="Pincode"
                    name="pincode"
                    onChange={handleChange}
                    value={data.pincode}
                    />
                    {formErr&&data.pincode.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <button className="saveInstiBtn" style={{textDecoration: "none"}} onClick={(e) => {handleSubmit(e);}}>Save Institute</button>
        </div>
    </div>
  )
}

export default UpdateInstituteForm