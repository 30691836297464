import React from 'react';
import '../../styles/InstiCard.css';
import { setInstitute } from '../../store/slices/UserSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function InstiCard(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSetInstituteId = async (e, id) => {
    e.stopPropagation();
    await dispatch(setInstitute(id));
    navigate("/updateInstitute", { replace: false });
  };

  const handleSubmit = async (e, id) => {
    e.stopPropagation();
    dispatch(setInstitute(id));
    navigate("/studentPage", { replace: false });
  };

  return (
    <div className="instiCardContainer" onClick={(e) => handleSubmit(e, props.insti._id)}>
      <div className="instiCardDetails">
        <div className="nameInsti">{props.insti.name}</div>
        <div className="placeInsti">{props.insti.place}</div>
        <div className="createdDate">Created on: {new Date(props.insti.createdDate).toLocaleDateString()}</div>
      </div>
      <div className="instiCardButtons">
        <button className="instiCardBtn" onClick={(e) => handleSetInstituteId(e, props.insti._id)}>Edit</button>
        <button className="instiCardBtn" onClick={(e) => {props.handleDelete(e, props.insti._id)}}>Delete</button>
      </div>
    </div>
  );
}
