import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import '../styles/Popup.css'

const Popup = (props) => {

    const handlePopupBtn = () => {
        props.setPopup(false)
    }

    return (
    <div>
        <div className='popup-overlay'>
            <div className="popup-overlay-container">
                <div className="popup-overlay-item">
                    <div className="popupMessage">{props.message}</div>
                    <button className='popup-btn' onClick={handlePopupBtn}>OK</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Popup