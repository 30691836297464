import React, { useEffect, useState } from 'react'
import axios from '../axios';
import { useSelector } from 'react-redux';
import UserCard from './Cards/UserCard';
import '../styles/AllUsersPage.css'
import Popup from './Popup';

const AllUsersPage = () => {

    const [allUsers, setAllUsers] = useState([])
    const [toggleReload, setToggleReload] = useState(false)
    const [popup, setPopup] = useState(false)
    const [message, setMessage] = useState("")


    const id = useSelector((state) => {
        return state.user.id
    })

    const handleDelete = async (userId, deleteId) => {
        let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
        
            let bodyContent = JSON.stringify({"deleteId": deleteId})
        
            let reqOptions = {
                url: `/users/deleteUser/${userId}`,
                method: "DELETE",
                headers: headersList,
                data: bodyContent
            };
            
            let reqOptionsUpdate = {
                url: `/users/getAllUsers/${userId}`,
                method: "GET",
                headers: headersList,
            };
        
            try {
                const response = await axios.request(reqOptions)
                if(response.status === 200)
                {
                    setMessage(response.data.message)
                    setPopup(true)
                    const newUsers = await axios.request(reqOptionsUpdate)
                    console.log(newUsers);
                    setAllUsers(newUsers.data)
                }
            } catch (err) {
                setMessage(err.response.data.message)
                setPopup(true)
                // window.alert(`${err.response.data.message}!`)
            }
      }

    const fetchAllUsers = async (id) => {

        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let reqOptions = {
            url: `/users/getAllUsers/${id}`,
            method: "GET",
            headers: headersList,
        };

        try {
            let response = await axios.request(reqOptions);
            if(response.status === 200){
                console.log(response);
                setAllUsers(response.data)
            } else if (response.status === 401) {
                    window.alert("Invalid Password");
            }
        } catch (err) {
            window.alert(`${err.response.data.message}!`)
        }
    }

    useEffect(() => {
        fetchAllUsers(id);
        setPopup(false)
    }, [])

    useEffect(() => {
        if(toggleReload)
        {
            fetchAllUsers(id)
            setPopup(true)
            setToggleReload(false)
        }
    }, [toggleReload])

    return (
        <div className='allUsersContainer'>
            <div className="titleUsers">All Users</div>
            <div className="allUserCards">
            {
                allUsers.map((user) => {
                    return <UserCard key={user._id} user={user} handleDelete={handleDelete} toggleReload={toggleReload} setToggleReload={setToggleReload} setMessage={setMessage}/>
                })
            }
            </div>
            {/* <div className="addUserBtn"><span className="icon">+</span></div> */}
            {
                popup ? <Popup setPopup={setPopup} message={message}/> : ""
            }
        </div>
    )
}

export default AllUsersPage