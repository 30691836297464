import React, { useState } from 'react'
import '../styles/CreateStudentForm.css'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { useNavigate } from 'react-router-dom'
import { updateStudents } from '../store/slices/StudentSlice'
import { useEffect } from 'react'
import { resetStudent } from '../store/slices/UserSlice'

const UpdateStudentForm = () => {

    const [data, setData] = useState({stuName: "", stuPhone: "", parentName: "", parentPhone: "", 
    instituteId: ""})
    const [formErr, setFormErr] = useState(false)
    
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const instituteId = useSelector((state) => {
        return state.user.currentInstitute
    })

    const studentId = useSelector((state) => {
        return state.user.currentStudent
    })

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(data.stuName.length === 0 || data.stuPhone.length === 0 || data.parentName.length === 0 || data.parentPhone.length === 0){
            setFormErr(true)
        } else {
            setFormErr(false)
            let customObj = {
                studentId: studentId,
                instituteId: instituteId,
                newData: data
            }
            dispatch(updateStudents(customObj))
            navigate(-1, {replace: true})
        }
    }

    const fetchStudentDetails = async (studentId) => {
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let bodyContent = JSON.stringify(data);

        let reqOptions = {
            url: `/students/getStudent/${studentId}`,
            method: "GET",
            headers: headersList,
            data: bodyContent,
        };

        try {
            let response = await axios.request(reqOptions);
            if(response.status === 200){
                console.log(response.data);
                setData(response.data)
            } else if (response.status === 401) {
                    window.alert("Invalid Password");
            }
        } catch (err) {
            window.alert(`${err.response.data.message}!`)
        }
    }

    useEffect(() => {
        fetchStudentDetails(studentId)
    }, [])

    const handleResetStudent = () => {
        dispatch(resetStudent())
    }

  return (
    <div className='stuFormMainContainer'>
        <h1 className="stuFormTitle">Update Student Details</h1>
        <div className="stuFormInnerContainer">
            <div className="stuInputContainer">
                <label htmlFor="stuName">Name of the Student</label>
                <input 
                        id="stuName"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Student's Name"
                        name="stuName"
                        onChange={handleChange}
                        value={data.stuName}
                        />
                        {formErr&&data.stuName.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="stuInputContainer">
                <label htmlFor="stuPhone">Student's Phone Number</label>
                <input 
                        id="stuPhone"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Phone"
                        name="stuPhone"
                        onChange={handleChange}
                        value={data.stuPhone}
                        />
                        {formErr&&data.stuPhone.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="stuInputContainer">
                <label htmlFor="parentName">Name of the Parent</label>
                <input 
                        id="parentName"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Parent's Name"
                        name="parentName"
                        onChange={handleChange}
                        value={data.parentName}
                        />
                        {formErr&&data.parentName.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="stuInputContainer">
                <label htmlFor="parentPhone">Parent's Phone Number</label>
                <input 
                        id="parentPhone"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Phone"
                        name="parentPhone"
                        onChange={handleChange}
                        value={data.parentPhone}
                        />
                        {formErr&&data.parentPhone.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            
            <button className="saveStuBtn" style={{textDecoration: "none"}} onClick={(e) => {handleSubmit(e);}}>Save Student</button>
        </div>
    </div>
  )
}

export default UpdateStudentForm