import React, { useEffect, useState } from 'react'
import axios from '../axios';
import '../styles/AllStudentsPage.css'
import StudentCard from './Cards/StudentCard';

const AllStudentsPage = () => {

    const [allStudents, setAllStudents] = useState([])

    const handleDelete = async (deleteId) => {
        let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
        
            let bodyContent = JSON.stringify({"id": deleteId})
        
            let reqOptions = {
                url: `/students/deleteStudent`,
                method: "DELETE",
                headers: headersList,
                data: bodyContent
            };
            
            let reqOptionsUpdate = {
                url: `/students/getAllStudents`,
                method: "GET",
                headers: headersList,
            };
        
            try {
                const response = await axios.request(reqOptions)
                if(response.status === 200)
                {
                    const newStudents = await axios.request(reqOptionsUpdate)
                    console.log(newStudents);
                    setAllStudents(newStudents.data)
                }
            } catch (err) {
                window.alert(`${err.response.data.message}!`)
            }
      }

    useEffect(() => {
        const fetchAllStudents = async () => {

            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
    
            let reqOptions = {
                url: `/students/getAllStudents`,
                method: "GET",
                headers: headersList,
            };
    
            try {
                let response = await axios.request(reqOptions);
                if(response.status === 200){
                    console.log(response);
                    setAllStudents(response.data)
                } else if (response.status === 401) {
                        window.alert("Invalid Password");
                }
            } catch (err) {
                window.alert(`${err.response.data.message}!`)
            }
    
        }
        fetchAllStudents();
    }, [])

    return (
        <div className='allStuContainer'>
            <div className="titleStu">All Students</div>
            <div className="allStuCards">
            {
                allStudents.map((student) => {
                    return <StudentCard key={student._id} student={student} handleDelete={handleDelete}/>
                })
            }
            </div>
            {/* <div className="addStuBtn"><span className="icon">+</span></div> */}
        </div>
    )
}

export default AllStudentsPage