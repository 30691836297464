import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axios';

export const getInstitutes = createAsyncThunk("userInstitutes", async (userId) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    let reqOptions = {
        url: `/institutes/${userId}`,
        method: "GET",
        headers: headersList,
    };
    
    try {
        const response = await axios.request(reqOptions)
        return response
    } catch (error) {
        return {message: error.message};
    }
})

export const deleteInstitutes = createAsyncThunk("deleteInstitutes", async (ids) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({"id": ids.instituteId})

    let reqOptions = {
        url: `/institutes/deleteInstitute`,
        method: "DELETE",
        headers: headersList,
        data: bodyContent
    };
    
    let reqOptionsUpdate = {
        url: `/institutes/${ids.userId}`,
        method: "GET",
        headers: headersList,
    };

    try {
        const response = await axios.request(reqOptions)
        if(response.status === 200)
        {
            const newInstitutes = await axios.request(reqOptionsUpdate)
            return newInstitutes
        }
    } catch (error) {
        return {message: error.message};
    }
})

export const updateInstitutes = createAsyncThunk("updateInstitutes", async (data) => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({"id": data.instituteId, "updatedData": data.newData})

    let reqOptions = {
        url: `/institutes/updateInstitute`,
        method: "PUT",
        headers: headersList,
        data: bodyContent
    };
    
    let reqOptionsUpdate = {
        url: `/students/${data.userId}`,
        method: "GET",
        headers: headersList,
    };

    try {
        const response = await axios.request(reqOptions)
        if(response.status === 200)
        {
            const newInstitutes = await axios.request(reqOptionsUpdate)
            return newInstitutes
        }
    } catch (error) {
        return {message: error.message};
    }
})

const instituteSlice = createSlice({
    name: "institute",
    initialState: {
        data: [],
        loading: false,
        error: ''
    },
    reducers: {
        resetStore: (state) => {
            state.data = []
            state.loading = false
            state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getInstitutes.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getInstitutes.fulfilled, (state, action) => {
            state.loading = false
            console.log(`PAYLOAD: ${action.payload.data}`);
            state.data = action.payload.data
            state.error = ''
        })
        builder.addCase(getInstitutes.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.error.message
        })
        builder.addCase(deleteInstitutes.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(updateInstitutes.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export const { resetStore } = instituteSlice.actions

export default instituteSlice.reducer 