import '../styles/Home.css'
import HomeCard from './Cards/HomeCard';
import { useSelector } from 'react-redux';

const Home = () => {

    const userData = useSelector((state) => {
        return state.user
    })

    const ROLES = {
        ADMIN: "admin",
        BDA: "bda"
    }

    const adminInfo = [
        {
            id: 0,
            data: "All Users"
        },
        {
            id: 1,
            data: "All Institutes"
        },
        {
            id: 2,
            data: "All Students"
        },
        {
            id: 3,
            data: "All Your Institutes"
        },
    ]

    const bdaInfo = [
        {
            id: 3,
            data: "Your Institutes"
        },
    ]

    return ( 
        <div className='homeContainer'>
            <div className="titleHome">
            {
                userData.role === ROLES.ADMIN &&
                `Welcome to the Admin Dashboard ${userData.username}`
            }
            {
                userData.role === ROLES.BDA &&
                `Welcome to the BDA Dashboard ${userData.username}`
            }
            </div>
            <div className="homeCards">
            {
                userData.role === ROLES.ADMIN &&
                adminInfo.map((item) => {
                    return (
                        <HomeCard key={item.id} item={item}/>
                    )
                })
            }
            {
                userData.role === ROLES.BDA &&
                bdaInfo.map((item) => {
                    return (
                        <HomeCard key={item.id} item={item}/>
                    )
                })
            }
            </div>
        </div>
     );
}

export default Home;