import React, { useState, useEffect } from 'react';
import Institute from '../Components/Cards/Institute';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInstitutes } from '../store/slices/InstituteSlice';
import '../styles/LandingPage.css';
import Popup from './Popup';
import { Link } from "react-router-dom";

const LandingPage = () => {
    const [popup, setPopup] = useState(false);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const dataLoad = useSelector((state) => state.institute);
    const navigate = useNavigate();
    const userId = useSelector((state) => {
        return state.user.id;
    });

    useEffect(() => {
        dispatch(getInstitutes(userId));
    }, [dispatch, userId]);

    const handleAddInstitute = () => {
        navigate('/addInstitute', { replace: false });
    };

    return (
        <div className='landingContainer'>
            <div className='titleLanding'>Your Institutes</div>
            <div className='buttonContainer'>
                <div className='addInstituteBtn' onClick={handleAddInstitute}>
                    <span className='icon'>+</span>
                </div>
            </div>
            <div className='instituteCards'>
                {dataLoad.loading && <div>Loading...</div>}
                {dataLoad.error ? <div>{dataLoad.error}</div> : null}
                {dataLoad.data && dataLoad.data.length > 0 ? (
                    dataLoad.data.map((institute) => (
                        <Institute
                            key={institute._id}
                            institute={institute}
                            userId={userId}
                            setMessage={setMessage}
                            setPopup={setPopup}
                        />
                    ))
                ) : (
                    <div className='nan'>No Institutes</div>
                )}
            </div>
            {popup ? <Popup setPopup={setPopup} message={message} /> : ''}
            <div className='backButton'>
                <Link to="/home" style={{ textDecoration: 'none', color: 'white' }}>
                    Back
                </Link>
            </div>
        </div>
    );
};

export default LandingPage;
