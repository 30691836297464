import './App.css';
import Navbar from './Components/Navbar';
import Login from './Components/Login/Login';
import Home from './Components/Home';
import ContactUs from './Components/ContactUs';
import LandingPage from './Components/LandingPage';
import CreateInstituteForm from './Components/CreateInstituteForm';
import CreateStudentForm from './Components/CreateStudentForm';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import StudentPage from './Components/StudentPage';
import AllUsersPage from './Components/AllUsersPage';
import AllInstiPage from './Components/AllInstiPage';
import AllStudentsPage from './Components/AllStudentsPage';
import UpdateStudentForm from './Components/UpdateStudentForm';
import UpdateInstituteForm from './Components/UpdateInstituteForm';
import { useState } from 'react';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><Navbar/><Login/></div>,
  },
  {
    path: "/home",
    element: <div><Navbar/><Home/></div>
  },
  {
    path: "/landingPage",
    element: <div><Navbar/><LandingPage/></div>
  },
  {
    path: "/contactUs",
    element: <div><Navbar/><ContactUs/></div>
  },
  {
    path: "/studentPage",
    element: <div><Navbar/><StudentPage/></div>
  },
  {
    path: "/addInstitute",
    element: <div><Navbar/><CreateInstituteForm/></div>
  },
  {
    path: "/addStudent",
    element: <div><Navbar/><CreateStudentForm/></div>
  },
  {
    path: "/updateStudent",
    element: <div><Navbar/><UpdateStudentForm/></div>
  },
  {
    path: "/updateInstitute",
    element: <div><Navbar/><UpdateInstituteForm/></div>
  },
  {
    path: "/allUsers",
    element: <div><Navbar/><AllUsersPage/></div>
  },
  {
    path: "/allInstitutes",
    element: <div><Navbar/><AllInstiPage/></div>
  },
  {
    path: "/allStudents",
    element: <div><Navbar/><AllStudentsPage/></div>
  }
]);

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div className="App">
      <RouterProvider router={router}>
        {/* <Navbar isLoggedIn = {isLoggedIn}/> */}
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </RouterProvider>
    </div>
  );
}

export default App;