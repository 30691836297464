import React from 'react'
import ReactDOM from 'react-dom/client'
// import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';


const root = ReactDOM.createRoot(document.getElementById('root'));
export const persistor = persistStore(store)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
// reportWebVitals();
