import React from 'react'
import '../styles/ConfirmPopup.css'

const ConfirmPopup = ({message, onConfirm, onCancel}) => {
  return (
    <>
      <div className="popup-overlay">
        <div className="popup-overlay-container">
            <div className="popup-overlay-item">
                <div className="popupMessage">
                    {message}
                </div>
                <div className="instiCardButtons">
                  <button className='instiCardBtn' onClick={onCancel}>No</button>
                  <button className='instiCardBtn' onClick={onConfirm}>Yes</button>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmPopup
