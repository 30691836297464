import React, { useEffect, useState } from 'react'
import axios from '../axios';
import { useSelector } from 'react-redux';
import '../styles/AllInstiPage.css'
import InstiCard from './Cards/InstiCard';
import Popup from './Popup';

const AllInstiPage = () => {

    const [popup, setPopup] = useState(false)
    const [message, setMessage] = useState("")

    const [allInsti, setAllInsti] = useState([])

    const id = useSelector((state) => {
        return state.user.id
    })

    const handleDelete = async (e, deleteId) => {
        e.stopPropagation()
        let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
        
            let bodyContent = JSON.stringify({"id": deleteId})
        
            let reqOptions = {
                url: `institutes/deleteInstitute`,
                method: "DELETE",
                headers: headersList,
                data: bodyContent
            };
            
            let reqOptionsUpdate = {
                url: `/institutes/getAllInstitutes`,
                method: "GET",
                headers: headersList,
            };
        
            try {
                const response = await axios.request(reqOptions)
                if(response.status === 200)
                {
                    setMessage(response.data.message)
                    setPopup(true)
                    const newInsti = await axios.request(reqOptionsUpdate)
                    console.log(newInsti);
                    setAllInsti(newInsti.data)
                }
            } catch (err) {
                setMessage(err.response.data.message)
                setPopup(true)
            }
      }

    useEffect(() => {
        const fetchAllInsti = async () => {

            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
    
            let reqOptions = {
                url: `/institutes/getAllInstitutes`,
                method: "GET",
                headers: headersList,
            };
    
            try {
                let response = await axios.request(reqOptions);
                if(response.status === 200){
                    console.log(response);
                    setAllInsti(response.data)
                } else if (response.status === 401) {
                        window.alert("Invalid Password");
                }
            } catch (err) {
                window.alert(`${err.response.data.message}!`)
            }
    
        }
        fetchAllInsti();
    }, [])

    return (
        <div className='allInstiContainer'>
            <div className="titleInsti">All Institutes</div>
            <div className="allInstiCards">
            {
                allInsti.map((insti) => {
                    return <InstiCard key={insti._id} insti={insti} handleDelete={handleDelete} />
                })
            }
            </div>
            {/* <div className="addInstiBtn"><span className="icon">+</span></div> */}
            {
                popup ? <Popup setPopup={setPopup} message={message}/> : ""
            }
        </div>
    )
}

export default AllInstiPage