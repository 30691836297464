import React from 'react'
import '../../styles/HomeCard.css'
import { useNavigate } from 'react-router-dom';

export default function HomeCard({item}) {

  const navigate = useNavigate()

  const handleClick = (id) => {
    switch(id){
      case 0: 
        navigate('/allUsers', {replace: false})
        break
      case 1: 
        navigate('/allInstitutes', {replace: false})
        break
      case 2: 
        navigate('/allStudents', {replace: false})
        break
      case 3: 
        navigate('/landingPage', {replace: false})
        break
      default:
        window.alert('Invalid Choice')
        break
    }
  }

  return (
    <div className='homeCardContainer'>
        <div className='homeTitle'>{item.data}</div>
        <button className='homeCardBtn' onClick={() => handleClick(item.id)}>&rarr;</button>
    </div>
  )
}
