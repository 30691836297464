import { configureStore } from "@reduxjs/toolkit";
import userSlice  from "./slices/UserSlice";
import instituteSlice  from "./slices/InstituteSlice";
import studentSlice  from "./slices/StudentSlice";
import persistReducer from "redux-persist/es/persistReducer";
import { combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";

const persistConfig = {
    key: "root",
    version: 1,
    storage
}

const reducer = combineReducers({
    user: userSlice,
    institute: instituteSlice,
    student: studentSlice
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
})

export default store;