import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetStore } from '../store/slices/InstituteSlice';
import { persistor } from '../index';
import { resetUser } from '../store/slices/UserSlice';
import { resetStudent } from '../store/slices/StudentSlice';
import '../styles/Navbar.css'

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    persistor.purge();
    dispatch(resetStore());
    dispatch(resetUser());
    dispatch(resetStudent());
    setIsLoggedIn(false);
  };

  return (
    <div className="navbar">
      <div className="items">
        <Link to="/" style={{ textDecoration: "none" }} className="navitem">
          Exampil
        </Link>
        {isLoggedIn && (
          <div>
            <Link
              to="/"
              style={{ textDecoration: 'none' }}
              className="navItem"
              onClick={handleLogout}
            >
              Logout
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
