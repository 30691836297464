import '../styles/ContactUs.css';

const ContactUs = () => {
    return ( 
        <div className="contactUsContainer">
            <div className='mainHolder'>
                <div className="contactForm">
                    <h2 className="contactHeader">Reach Out To Us</h2>
                    <h4 className="contactCaption">Feel free to contact us anytime. we will get back to you as soon as we can!</h4>
                    <input 
                        className="contactItems" 
                        type="text" 
                        placeholder="Name"
                        name="name"
                    />
                    <input 
                        className="contactItems" 
                        type="text" 
                        placeholder="Email"
                        name="email"
                    />
                    <input 
                        className="contactItems" 
                        type="text" 
                        placeholder="Message"
                        name="message"
                    />
                    <button className='contactBtn'>Submit</button>
                </div>
                <div className="info">
                    <h2 className="infoHeader">Info</h2>
                    <h4 className="infoContent">+91 9930552280</h4>
                    <h4 className="infoContent">Corporate Address: 1608, Lodha Supremus, Powai, Mumbai 400072</h4>
                    <h4 className="infoContent">hello@exampil.com</h4>
                    <h4 className="infoContent">10:00 am to 6:00 pm</h4>
                </div>
            </div>
        </div>
     );
}
 
export default ContactUs;