import '../../styles/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios';
import { useState, useEffect } from 'react'; 
import { useDispatch } from 'react-redux';
import { addUser } from '../../store/slices/UserSlice';

const Login = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [data, setData] = useState({ username: '', password: '' });
    const [formErr, setFormErr] = useState(false);

    const handleLogin = async () => {
        try {
            const response = await axios.post('/users/login', data); 
            if (response.status === 200) {
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 30);

                const sessionData = {
                    token: response.data.token, 
                    expirationDate: expirationDate.toISOString(), 
                };

                document.cookie = `sessionData=${JSON.stringify(sessionData)}`;

                dispatch(addUser(response.data.data));
                navigate('/home', { replace: true });
            }
        } catch (err) {
            console.error('an error occurred: ', err);
        }
    };

    useEffect(() => {
        const checkSession = () => {
            const sessionDataString = document.cookie
                .split('; ')
                .find(cookie => cookie.startsWith('sessionData='));

            if (sessionDataString) {
                const sessionData = JSON.parse(sessionDataString.split('=')[1]);
                const expirationDate = new Date(sessionData.expirationDate);

                if (expirationDate > new Date()) {
                } else {
                    document.cookie = 'sessionData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                }
            }
        };

        checkSession(); 

        return () => {
        };
    }, []);

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (data.username.length === 0 || data.password.length === 0) {
            setFormErr(true);
        } else {
            setFormErr(false);
            let headersList = {
                Accept: '*/*',
                'Content-Type': 'application/json',
            };

            let bodyContent = JSON.stringify(data);

            let reqOptions = {
                url: '/users/login',
                method: 'POST',
                headers: headersList,
                data: bodyContent,
            };

            try {
                let response = await axios.request(reqOptions);
                console.log(response);
                if (response.status === 200) {
                    dispatch(addUser(response.data.data));
                    navigate('/home', { replace: true });
                }
            } catch (err) {
                console.error('an error occurred: ', err);
            }
        }
    };

    return (
        <div className="mainContainer">
            <div className="container">
                <h1 className="loginTitle">Login</h1>
                <div className="inputfield">
                    <input
                        className="inputs"
                        type="text"
                        placeholder="username"
                        name="username"
                        onChange={handleChange}
                        value={data.username}
                    />
                    {formErr && data.username.length <= 0 ? (
                        <div className="formError">Field cannot be empty</div>
                    ) : (
                        ''
                    )}
                    <input
                        className="inputs"
                        type="password"
                        placeholder="password"
                        name="password"
                        onChange={handleChange}
                        value={data.password}
                    />
                    {formErr && data.password.length <= 0 ? (
                        <div className="formError">Field cannot be empty</div>
                    ) : (
                        ''
                    )}
                    <div
                        className="loginBtn"
                        style={{ textDecoration: 'none' }}
                        onClick={handleSubmit}
                    >
                        Continue
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
