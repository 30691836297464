import React, { useState } from 'react'
import '../styles/UpdateUserForm.css'
import { useSelector } from 'react-redux'
import axios from '../axios'
import { useEffect } from 'react'

const UpdateUserForm = (props) => {

    const [data, setData] = useState({username: "", role: "", allowChanges: ""})
    const [anim, setAnim] = useState(false)
    const [formErr, setFormErr] = useState(false)

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value})
    }

    const adminId = useSelector((state) => {
        return state.user.id
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(data.username.length === 0 || data.role.length === 0 || data.allowChanges.length === 0){
            setFormErr(true)
        }else{
            setFormErr(false)
            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
    
            let bodyContent = JSON.stringify({"editId": props.userId, "updatedData": data});
            console.log(bodyContent);
    
            let reqOptions = {
                url: `/users/updateUser/${adminId}`,
                method: "PUT",
                headers: headersList,
                data: bodyContent,
            };
    
            try {
                let response = await axios.request(reqOptions);
                if(response.status === 200){
                    props.setToggleReload(true)
                    setAnim(!anim)
                    props.setMessage(response.data.message)
                    props.setIsActive(!props.isActive)
                } else {
                    props.setMessage(response.data.message)
                }
            } catch (err) {
                props.setMessage(err.response.data.message)
                // window.alert(`${err.response.data.message}!`)
            }
        }
    }

    const fetchUserDetails = async (userId) => {
        
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        let reqOptions = {
            url: `/users/${userId}`,
            method: "GET",
            headers: headersList,
        };

        try {
            let response = await axios.request(reqOptions);
            if(response.status === 200){
                console.log(response.data);
                setData(response.data)
            } else if (response.status === 404) {
                    window.alert("Failed to Update User");
            }
        } catch (err) {
            window.alert(`${err.response.data.message}!`)
        }
    }

    useEffect(() => {
        console.log(props.userId);
        setAnim(!anim)
        fetchUserDetails(props.userId)
    }, [])

  return (
    <div className={`overlay ${anim ? 'show' : ''}`}>
        <div className={`userFormMainContainer`}>
        <h1 className="userFormTitle">Update User Details</h1>
            <div className="userFormInnerContainer">
                <div className="userInputContainer">
                    <label htmlFor="username">Username of the User</label>
                    <input 
                            id="username"
                            className="userInputs" 
                            type="text" 
                            placeholder="Username"
                            name="username"
                            onChange={handleChange}
                            value={data.username}
                            />
                            {formErr&&data.username.length<=0?<div className='formError'>Field cannot be empty</div>:""}
                </div>
                <div className="userInputContainer">
                    <label htmlFor="role">Role of the User</label>
                    <input 
                            id="role"
                            className="userInputs" 
                            type="text" 
                            placeholder="User Role"
                            name="role"
                            onChange={handleChange}
                            value={data.role}
                            />
                            {formErr&&data.role.length<=0?<div className='formError'>Field cannot be empty</div>:""}
                </div>
                <div className="userInputContainer">
                    <label htmlFor="allowChanges">User Permissions</label>
                    <input 
                            id="allowChanges"
                            className="userInputs" 
                            type="text" 
                            placeholder="Can Make Admin Edits?"
                            name="allowChanges"
                            onChange={handleChange}
                            value={data.allowChanges}
                            />
                            {formErr&&data.allowChanges.length<=0?<div className='formError'>Field cannot be empty</div>:""}
                </div>
                <button className="saveUserBtn" style={{textDecoration: "none"}} onClick={(e) => {handleSubmit(e);}}>Save User</button>
            </div>
        </div>
    </div>
  )
}

export default UpdateUserForm