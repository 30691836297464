import React, { useState } from 'react';
import '../../styles/Student.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStudent } from '../../store/slices/UserSlice';
import { deleteStudents } from '../../store/slices/StudentSlice';
import ConfirmPopup from '../ConfirmPopup';

export default function Student(props) {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleConfirmDelete = () => {
    let customObj = {
      studentId: props.student._id,
      instituteId: props.instituteId,
    };

    dispatch(deleteStudents(customObj));
    props.setMessage('Student Deleted Successfully');
    props.setPopup(true);
    navigate('/studentPage', { replace: false });
    setShowConfirmPopup(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmPopup(false);
  };

  const handleDelete = () => {
    setShowConfirmPopup(true);
  };

  const handleSetStudentId = async (id) => {
    dispatch(setStudent(id));
    navigate('/updateStudent', { replace: false });
  }

  return (
    <div className='studentCardContainer'>
      <div className="studentDetails">
        <div className='studentName'>{props.student.stuName}</div>
        <div className='studentPhone'>{props.student.stuPhone}</div>
      </div>
      <div className="studentButtons">
        <button className='studentBtn' onClick={() => handleSetStudentId(props.student._id)}>Edit</button>
        <button className='studentBtn' onClick={handleDelete}>Delete</button>
      </div>
      {showConfirmPopup && (
        <ConfirmPopup
          message="Are you sure you want to delete the student?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
}
