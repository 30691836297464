import React from 'react'
import '../../styles/UserCard.css'
import { useSelector } from 'react-redux'
import UpdateUserForm from '../UpdateUserForm'
import { useState } from 'react'

export default function UserCard(props) {

  const [isActive, setIsActive] = useState(false)

  const userId = useSelector((state) => {
    return state.user.id
  })

  const handleUpdateUser = (userId) => {
    setIsActive(!isActive)    
  }

  return (
    <div>
      <div className='userCardContainer'>
        <div className="userDetails">
          <div className='usernameUser'>{props.user.username}</div>
          <div className='roleUser'>{props.user.role}</div>
        </div>
          <div className="userButtons">
            <button className='userCardBtn' onClick={() => {handleUpdateUser(props.user._id)}}>Update</button>
            <button className='userCardBtn' onClick={() => {props.handleDelete(userId, props.user._id)}}>Delete</button>
          </div>
      </div>
      {
        isActive && <UpdateUserForm isActive={isActive} setIsActive={setIsActive} userId={props.user._id} toggleReload={props.toggleReload} setToggleReload={props.setToggleReload} setMessage={props.setMessage}/>
      }
    </div>

  )
}
