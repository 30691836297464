import React, { useState, useEffect } from 'react'
import '../styles/CreateStudentForm.css'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { useNavigate } from 'react-router-dom'
import { resetStudent } from '../store/slices/UserSlice'

const CreateStudentForm = () => {

    const [data, setData] = useState({stuName: "", stuPhone: "", parentName: "", parentPhone: "", 
    instituteId: ""})
    const [formErr, setFormErr] = useState(false)
    const [message, setMessage] = useState("")
    const [popup, setPopup] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const instituteId = useSelector((state) => {
        return state.user.currentInstitute
    })

    useEffect(() => {
      setData((prevData) => ({ ...prevData, instituteId: instituteId }));
    }, [instituteId]);

    const handleChange = ({ currentTarget: input }) => {
        const { name, value } = input;

        const nameRegex = /^[A-Za-z\s]+$/; 
        const phoneRegex = /^\d{10}$/; 
    
        if (name === 'stuName' || name === 'parentName') {
          if (nameRegex.test(value) || value === '') {
            setFormErr(false);
            setData({ ...data, [name]: value });
          } else {
            setFormErr(true);
          }
        } else if (name === 'stuPhone' || name === 'parentPhone') {
          if (phoneRegex.test(value) || value === '') {
            setFormErr(false);
            setData({ ...data, [name]: value });
          } else {
            setFormErr(true);
          }
        } else {
          setData({ ...data, [name]: value });
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(data.stuName.length === 0 || data.stuPhone.length === 0 || data.parentName.length === 0 || data.stuPhone.length === 0){
            setFormErr(true)
        } else {
            setFormErr(false)
            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
    
            let bodyContent = JSON.stringify(data);
            console.log(data);
    
            let reqOptions = {
                url: "/students/addStudent",
                method: "POST",
                headers: headersList,
                data: bodyContent,
            };
    
            try {
                const response = await axios.request(reqOptions);
                if(response.status === 200){
                    setMessage(response.data.message)
                    setPopup(true) 
                    navigate(-1, { replace: true });
                    console.log(response);
                } else {
                    setMessage(response.data.message)
                    setPopup(true)                
                }
            } catch (err) {
                setMessage(err.response.data.message)
                setPopup(true)
            }
        }
    }

    const handleResetStudent = () => {
        dispatch(resetStudent())
    }

    const handleCancel = () => {
        navigate("/studentPage");
    }

  return (
    <div className='stuFormMainContainer'>
        <h1 className="stuFormTitle">Add Student</h1>
        <div className="stuFormInnerContainer">
            <div className="stuInputContainer">
                <label htmlFor="stuName">Name of the Student</label>
                <input 
                        id="stuName"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Student's Name"
                        name="stuName"
                        onChange={handleChange}
                        />
                        {formErr&&data.stuName.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="stuInputContainer">
                <label htmlFor="stuPhone">Student's Phone Number</label>
                <input 
                        id="stuPhone"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Phone"
                        name="stuPhone"
                        onChange={handleChange}
                        />
                        {formErr&&data.stuPhone.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="stuInputContainer">
                <label htmlFor="parentName">Name of the Parent</label>
                <input 
                        id="parentName"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Parent's Name"
                        name="parentName"
                        onChange={handleChange}
                        />
                        {formErr&&data.parentName.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            <div className="stuInputContainer">
                <label htmlFor="parentPhone">Parent's Phone Number</label>
                <input 
                        id="parentPhone"
                        className="stuInputs" 
                        type="text" 
                        placeholder="Phone"
                        name="parentPhone"
                        onChange={handleChange}
                        />
                        {formErr&&data.parentPhone.length<=0?<div className='formError'>Field cannot be empty</div>:""}
            </div>
            
            <div className="instiCardButtons" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className='instiCardBtn' onClick={handleCancel} style={{ width: '200px' }}>Cancel</button>
                <button className='instiCardBtn' onClick={(e) => {handleSubmit(e);}} style={{ width: '200px' }}>Create Student</button>
            </div>
        </div>
    </div>
  )
}

export default CreateStudentForm