import React, { useState } from 'react'
import '../styles/CreateInstituteForm.css'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../axios'
import { useNavigate } from 'react-router-dom'
import resetInstitute from '../store/slices/UserSlice'
import Popup from '../Components/Popup'

const CreateInstituteForm = () => {

    const [data, setData] = useState({name: "", place: "", pincode: "", userId: ""})
    const [formErr, setFormErr] = useState(false)
    const [message, setMessage] = useState("")
    const [popup, setPopup] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => {
        return state.user.id
    })

    const handleChange = ({ currentTarget: input }) => {
        const { name, value } = input;
        let newValue = value;
      
        if (name === "name" || name === "place") {
          newValue = value.replace(/[^A-Za-z\s]/g, "");
        } else if (name === "pincode") {    
          newValue = value.replace(/\D/g, "");
        }
      
        setData({ ...data, "userId": userId, [name]: newValue });
      }
      

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(data.name.length === 0 || data.place.length === 0 || data.pincode.length === 0){
            setFormErr(true)
        } else {
            setFormErr(false)
            let headersList = {
                Accept: "*/*",
                "Content-Type": "application/json",
            };
    
            let bodyContent = JSON.stringify(data);
            console.log(bodyContent);
    
            let reqOptions = {
                url: "/institutes/addInstitute",
                method: "POST",
                headers: headersList,
                data: bodyContent,
            };
    
            try {
                const response = await axios.request(reqOptions);
                if(response.status === 200){
                    setMessage(response.data.message)
                    setPopup(true) 
                    navigate(-1, { replace: true });
                    console.log(response);
                } else {
                    setMessage(response.data.message)
                    setPopup(true)
                }
            } catch (err) {
                setMessage(err.response.data.message)
                setPopup(true)
            }
        }
    }

    const handleResetInstitute = () => {
        dispatch(resetInstitute())
    }

    const handleCancel = () => {
        navigate("/landingPage");
    }

  return (
    <div className='instiFormMainContainer'>
        <h1 className="instiFormTitle">Add Institute</h1>
        <div className="instiFormInnerContainer">
            <div className="instiInputContainer">
                <label htmlFor="instiName">Name of the Institute</label>
                    <input
                        id="instiName"
                        className="instiInputs"
                        type="text"
                        placeholder="Name"
                        name="name"
                        pattern="[A-Za-z\s]+"
                        onChange={handleChange}
                    />
                    {formErr && data.name.length <= 0 ? (
                        <div className="formError">Field cannot be empty</div>
                    ) : (
                        ""
                    )}
            </div>
            <div className="instiInputContainer">
                <label htmlFor="instiPlace">Place of the Institute</label>
                <input
                    id="instiPlace"
                    className="instiInputs"
                    type="text"
                    placeholder="Place"
                    name="place"
                    pattern="[A-Za-z\s]+"
                    onChange={handleChange}
                />
                {formErr && data.place.length <= 0 ? (
                    <div className="formError">Field cannot be empty</div>
                ) : (
                    ""
                )}
            </div>
            <div className="instiInputContainer">
                <label htmlFor="instiPincode">Pincode of Location</label>
                <input
                    id="instiPincode"
                    className="instiInputs"
                    type="text"
                    placeholder="Pincode"
                    name="pincode"
                    pattern="[0-9]+"
                    onChange={handleChange}
                />
                {formErr && data.pincode.length <= 0 ? (
                    <div className="formError">Field cannot be empty</div>
                ) : (
                    ""
                )}
            </div>
            <div className="instiCardButtons" style={{ display: 'flex', justifyContent: 'center' }}>
                <button className='instiCardBtn' onClick={handleCancel} style={{ width: '200px' }}>Cancel</button>
                <button className='instiCardBtn' onClick={(e) => {handleSubmit(e);}} style={{ width: '200px' }}>Create Institute</button>
            </div>
            {
                popup ? <Popup setPopup={setPopup} message={message}/> : ""
            }
        </div>
    </div>
  )
}

export default CreateInstituteForm