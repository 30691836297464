import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {id: "", currentInstitute: "", currentStudent: "", role: "", username: "", allowChanges: false},
    reducers: {
        addUser(state, action){
            state.id = action.payload._id
            state.role = action.payload.role
            state.username = action.payload.username
            state.allowChanges = action.payload.allowChanges
        },
        removeUser(state, action){

        },
        updateUser(state, action){

        },
        resetUser(state){
            state.id = ""
            state.currentInstitute = ""
            state.currentStudent = ""
            state.role = ""
            state.username = ""
            state.allowChanges = false
        },
        setInstitute(state, action){
            state.currentInstitute = action.payload
        },
        setStudent(state, action){
            state.currentStudent = action.payload
        },
        resetStudent(state){
            state.currentStudent = ""
        },
        resetInstitute(state){
            state.currentInstitute = ""
            state.currentStudent = ""
        }
    },
})

export default userSlice.reducer 

export const { addUser, resetUser, setInstitute, setStudent, resetStudent, resetInstitute } = userSlice.actions;