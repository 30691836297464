import React, { useState, useEffect } from 'react'
import Student from '../Components/Cards/Student'
import { useDispatch, useSelector } from 'react-redux';
import '../styles/StudentPage.css'
import { getStudents } from '../store/slices/StudentSlice';
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';
import { Link } from "react-router-dom";

const StudentPage = () => {
    const [popup, setPopup] = useState(false)
    const [message, setMessage] = useState("")

    const dispatch = useDispatch()
    const dataLoad = useSelector((state) => state.student)
    const navigate = useNavigate()
    const instituteId = useSelector((state) => {
        return state.user.currentInstitute
    })
    
    useEffect(() => {
        dispatch(getStudents(instituteId))
    }, [])

    const handleAddStudent = () => {
        navigate("/addStudent", { replace: false })
    }

    return (
        <div className='studentContainer'>
            <div className="titleStudent">Students</div>
            <div className="studentCards">
                {dataLoad.loading && <div>Loading...</div>}
                {!dataLoad.loading && dataLoad.error ? <div>{dataLoad.error}</div> : null}
                {dataLoad.data && dataLoad.data.length ? (
                    dataLoad.data.map((student) => (
                        <Student key={student._id} student={student} instituteId={instituteId} setMessage={setMessage} setPopup={setPopup} />
                    ))
                ) : null}
                {(!dataLoad.loading && dataLoad.data && dataLoad.data.length === 0) ? (
                    <div className='nan'>No Students</div>
                ) : null}
            </div>
            <div className="addStuBtn" onClick={handleAddStudent}><span className="icon">+</span></div>
            {popup ? <Popup setPopup={setPopup} message={message} /> : ""}
            <div className='backButton'>
                <Link to="/landingPage" style={{ textDecoration: 'none', color: 'white' }}>
                    Back
                </Link>
            </div>
        </div>
    )
}

export default StudentPage
