import React from 'react'
import '../../styles/StudentCard.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStudent } from '../../store/slices/UserSlice';

export default function StudentCard(props) {

  let navigate = useNavigate();
  const dispatch = useDispatch()
  
  const handleSetStudentId = async (id) => {
    dispatch(setStudent(id))
    navigate("/updateStudent", { replace: false });
  }

  return (
    <div className='studentCardMainContainer'>
        <div className="stuDetails">
          <div className='stuName'>{props.student.stuName}</div>
          <div className='stuPhone'>{props.student.stuPhone}</div>
        </div>
        <div className="stuButtons">
          <button className='stuBtn' onClick={() => handleSetStudentId(props.student._id)}>Edit</button>
          <button className='stuBtn' onClick={() => props.handleDelete(props.student._id)}>Delete</button>
        </div>
    </div>
  )
}