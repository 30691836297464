import React, { useState } from 'react';
import '../../styles/InstituteCard.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetInstitute, setInstitute } from '../../store/slices/UserSlice';
import { deleteInstitutes } from '../../store/slices/InstituteSlice';
import ConfirmPopup from '../ConfirmPopup';
import Popup from '../Popup'; 

export default function Institute(props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); 

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => {
    return state.user.id;
  });

  const handleDelete = async (e, id, userId) => {
    e.stopPropagation();
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteConfirmation(false);

    const customObj = {
      instituteId: props.institute._id,
      userId: userId,
    };
    await dispatch(deleteInstitutes(customObj));
    setShowSuccessMessage(true); 
    props.setMessage('Institute Deleted Successfully');
    props.setPopup(true);
    setTimeout(() => {
      navigate('/landingPage', { replace: true });
    }, 1000);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    navigate('/landingPage', { replace: true });
  };

  const handleSubmit = async (e, id) => {
    e.stopPropagation();
    dispatch(setInstitute(id));
    navigate('/studentPage', { replace: false });
  };

  const handleResetInstitute = () => {
    dispatch(resetInstitute());
  };

  const handleSetInstituteId = async (e, id) => {
    e.stopPropagation();
    await dispatch(setInstitute(id));
    navigate('/updateInstitute', { replace: false });
  };

  return (
    <div className="cardContainer" onClick={(e) => handleSubmit(e, props.institute._id)}>
      <div className="instituteDetails">
        <div className="title">{props.institute.name}</div>
        <div className="place">{props.institute.place}</div>
      </div>
      <div className="instituteButtons">
        <button className="instituteBtn" onClick={(e) => handleSetInstituteId(e, props.institute._id)}>
          Edit
        </button>
        <button className="instituteBtn" onClick={(e) => handleDelete(e, props.institute._id, props.userId)}>
          Delete
        </button>
      </div>
      {showDeleteConfirmation && (
        <ConfirmPopup
          message="Are you sure you want to delete this institute?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      {showSuccessMessage && (
        <Popup message="Institute deleted successfully" setPopup={setShowSuccessMessage} />
      )}
    </div>
  );
}
